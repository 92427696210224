import * as React from 'react';
import { Props } from '..';

const ChevronLeft: React.FC<Props> = ({
  color = 'currentColor',
  strokeWidth = 2.044,
  ...props
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={strokeWidth}
      d="M17.5 1.1L6.6 12l10.9 10.9"
    />
  </svg>
);

export default ChevronLeft;
