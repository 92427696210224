import { useContext } from 'react';
import StateContext, { GlobalState } from '../../context/StateContext';

const useStateContext = (): GlobalState => {
  const context = useContext(StateContext);

  return context;
};

export default useStateContext;
