type SafeStorageAPI = {
  /** @private */
  __storage: any;
  /** Sets a value in session storage or fallback global */
  setItem: (key: string, value: string) => void;
  /** Get the value for this key */
  getItem: (key: string) => string | null;
  /** Removes an item from session storage */
  removeItem: (key: string) => void;
  /** Clears the entire session storage, when available */
  clear: () => void;
};

const TEST_KEY = '_dh_session_storage_test';

const canAccessSessionStorage = (): boolean => {
  try {
    // Check if window is defined
    if (window && 'sessionStorage' in window) {
      // Set a test key in session storage
      window.sessionStorage.setItem(TEST_KEY, 'true');
      // Get the test key from session storage
      const item = window.sessionStorage.getItem(TEST_KEY);
      if (!item) return false;

      window.sessionStorage.removeItem(TEST_KEY);
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

const mockedSessionStorage: SafeStorageAPI = {
  __storage: {},
  clear() {
    this.__storage = {};
  },
  setItem(key, value) {
    this.__storage[key] = value;
  },
  getItem(key) {
    return this.__storage[key];
  },
  removeItem(key) {
    delete this.__storage[key];
  },
};

export const safeSessionStorage = canAccessSessionStorage()
  ? window.sessionStorage
  : mockedSessionStorage;

export default safeSessionStorage;
