import Color from 'color';
import { keys } from 'ramda';
import { StyleConfig, StyleConfigColorPair } from '../../../shared/types';

export type ColorVariantBase = {
  base: string;
  text: string;
};

export type ColorVariant = ColorVariantBase & {
  darker: string;
};

export type IColorPalette<T> = {
  success: T;
  error: T;
  primary: T;
  secondary: T;
};

export type SystemColorPaletteBase = IColorPalette<ColorVariantBase>;
export type SystemColorPalette = IColorPalette<ColorVariant>;
export type BaseColor = keyof SystemColorPalette;
export type BaseColorVariant = keyof ColorVariant;

const baseColorPalette: SystemColorPaletteBase = {
  success: {
    base: '#3DA07B',
    text: '#ffffff',
  },
  error: {
    base: '#DD121B',
    text: '#ffffff',
  },
  primary: {
    // Buttons (primary CTA)

    base: '#35265E',
    text: '#fff',
  },
  secondary: {
    // Steps, map tooltip, loading animation, etc..
    base: '#00A3C3',
    text: '#fff',
  },
};

const generateColorVariant = (
  baseColor: ColorVariantBase | StyleConfigColorPair,
): ColorVariant => {
  if ('background' in baseColor) {
    return {
      base: baseColor.background,
      text: baseColor.color,
      darker: Color(baseColor.background).darken(0.2).hex(),
    };
  }

  return {
    ...baseColor,
    darker: Color(baseColor.base).darken(0.2).hex(),
  };
};

export const generateColorPalette = (
  styleConfig: StyleConfig,
): SystemColorPalette => {
  const colorPalette = {
    // Default colors
    ...keys(baseColorPalette).reduce(
      (palette, colorVariant) => ({
        ...palette,
        [colorVariant]: generateColorVariant(baseColorPalette[colorVariant]),
      }),
      {} as SystemColorPalette,
    ),
    // User colors
    ...keys(styleConfig).reduce(
      (palette, colorVariant) => ({
        ...palette,
        [colorVariant]: generateColorVariant(styleConfig[colorVariant]),
      }),
      {} as { [key in keyof StyleConfig]?: ColorVariant },
    ),
  };

  return colorPalette;
};

export default baseColorPalette;
