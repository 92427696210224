import generateId from '../generateId';
import { safeLocalStorage } from '../safeLocalStorage';

export const USER_ID_STORAGE_KEY = 'dh_user_id';

const getUserId = (): string => {
  const idFromStorage = safeLocalStorage.getItem(USER_ID_STORAGE_KEY);

  if (idFromStorage) return idFromStorage;
  const newId = generateId('user');
  safeLocalStorage.setItem(USER_ID_STORAGE_KEY, newId);

  return newId;
};

export default getUserId;
