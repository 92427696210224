import React from 'react';
import styled, { css } from 'styled-components';
import useHeightContext from '../../../../hooks/useHeightContext';
import useWidgetContext from '../../../../hooks/useWidgetContext';
import Divider from '../../../Divider';
import JustificationContainer from '../../../JustificationContainer';
import Logo from '../../../Logo';

type Props = {
  dataTestId?: string;
  children: React.ReactNode;
};

const HeaderContainer: React.FC<Props> = ({ children, ...rest }) => {
  const { logo } = useWidgetContext();
  const { headerRef } = useHeightContext();

  return (
    <Container>
      <StyledJustification
        {...rest}
        align="center"
        justification="space-between"
        padding={['xxs', 'base']}
      >
        <Logo src={logo.src} />
        {children}
      </StyledJustification>
      <Divider margin={[null, null]} ref={headerRef} />
    </Container>
  );
};

const StyledJustification = styled(JustificationContainer)(
  ({ theme }) => css`
    height: ${CONTAINER_HEIGHT}px;
    color: ${theme.color('primary')};
  `,
);

const CONTAINER_HEIGHT = 65;
const Container = styled.div(
  ({ theme }) => css`
    width: 100%;
    padding: 0;
    z-index: ${theme.getSystem().zIndex.header};
  `,
);

export default HeaderContainer;
