import React from 'react';
import { Props } from '..';

const MortgageSavingsCheck: React.FC<Props> = ({ color, ...props }) => (
  <svg
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M28.0001 37.4221C27.4668 37.5998 26.9334 37.5998 26.3112 37.5998C22.5779 37.5998 19.6445 34.5776 19.6445 30.9332C19.6445 27.2887 22.6668 24.2665 26.3112 24.2665C29.9556 24.2665 32.9779 27.2887 32.9779 30.9332C32.9779 31.5554 32.889 32.1776 32.7112 32.7998"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.4671 36.9776L20.5338 39.822C20.2671 40.0887 20.356 40.7109 20.8005 40.7109H22.3116V59.1998H47.556V40.7109H48.9782C49.4227 40.7109 49.6893 40.1776 49.2449 39.822L35.8227 26.8443C35.3782 26.3998 34.5782 26.3998 34.1338 26.8443L32.4449 28.4443"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.8672 39.4665L34.5783 30.9332C34.7561 30.7554 34.9339 30.7554 35.1116 30.9332L43.9116 39.4665"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M43.4676 26.4887H40.2676V31.1998L43.4676 34.222V26.4887Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.4447 22.9332C23.3902 22.9332 25.778 20.5453 25.778 17.5998C25.778 14.6543 23.3902 12.2665 20.4447 12.2665C17.4991 12.2665 15.1113 14.6543 15.1113 17.5998C15.1113 20.5453 17.4991 22.9332 20.4447 22.9332Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.0665 13.5111C31.5702 13.5111 33.5999 11.4815 33.5999 8.97778C33.5999 6.47409 31.5702 4.44444 29.0665 4.44444C26.5628 4.44444 24.5332 6.47409 24.5332 8.97778C24.5332 11.4815 26.5628 13.5111 29.0665 13.5111Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.111 33.511C25.6888 33.511 24.5332 32.3555 24.5332 30.9332C24.5332 29.511 25.6888 28.3555 27.111 28.3555"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.2891 30.9332H26.4002"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default MortgageSavingsCheck;
