import '../styles/globals.css';
import qs from 'query-string';
import { mergeDeepRight } from 'ramda';
import React, { useState, useEffect, useRef, useContext } from 'react';
import type { AppProps } from 'next/app';
import { ThemeProvider } from 'styled-components';
import getTheme from '../src/theme';
import useWidgetContext from '../src/hooks/useWidgetContext';
import WidgetContext, {
  IWidgetContext,
  type ConsentParams,
} from '../src/context/WidgetContext';
import windowConfigToWidgetContext from '../src/utils/windowConfigToWidgetContext';
import Layout from '../src/components/Layout';
import useMessaging from '../src/hooks/useMessaging';
import { FrameSizeSelector, HostInfo, WindowConfig } from '../src/shared/types';
import StateProvider from '../src/components/StateProvider';
import HeightContext from '../src/context/HeightContext';
import HostInfoContext from '../src/context/HostInfoContext';
import Script from 'next/script';
import useSetupAnalytics from '../src/hooks/useSetupAnalytics';
import Head from 'next/head';
import FrameSizeContext from '../src/context/FrameSizeContext';

import useFireTrackingEvent from '../src/hooks/useFireTrackingEvent';
import TrackingContext, {
  DEFAULT_TRACKING_CONTEXT_VALUE,
} from '../src/context/TrackingContext';
import serializeConsentOptions from '../src/utils/serializeConsentOptions';

const App: React.FC<AppProps> = ({ Component, pageProps }) => {
  const widgetContext = useWidgetContext();

  const headerRef = useRef<HTMLHRElement | null>(null);
  const footerRef = useRef<HTMLHRElement | null>(null);

  return (
    <TrackingContext.Provider
      value={{
        ...DEFAULT_TRACKING_CONTEXT_VALUE,
        accountId: widgetContext.accountId,
      }}
    >
      <WithAnalytics>
        <HeightContext.Provider value={{ footerRef, headerRef }}>
          <ThemeProvider theme={getTheme(widgetContext.styleConfig)}>
            <StateProvider>
              <Layout>
                <Component {...pageProps} />
              </Layout>
            </StateProvider>
          </ThemeProvider>
        </HeightContext.Provider>
      </WithAnalytics>
    </TrackingContext.Provider>
  );
};

const WithAnalytics: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const sessionStarted = useRef(false);
  const { sessionId } = useContext(TrackingContext);
  const widgetContext = useWidgetContext();
  const fireTrackingEvent = useFireTrackingEvent();
  useSetupAnalytics(widgetContext);

  useEffect(() => {
    if (sessionStarted.current === false) {
      fireTrackingEvent({
        event: 'SessionStart',
        sessionId: sessionId,
      });
      sessionStarted.current = true;
    }
  }, [fireTrackingEvent, sessionId]);

  return <>{children}</>;
};

const WithConfig: React.FC<AppProps & { children: React.ReactNode }> = ({
  children,
  ...props
}) => {
  const [consentParams, setConsentParams] = useState<ConsentParams>({
    ad_personalization: 'denied',
    ad_storage: 'denied',
    ad_user_data: 'denied',
    analytics_storage: 'denied',
  });

  const [widgetContext, setWidgetContext] = useState<IWidgetContext | null>(
    null,
  );
  const [frameSizeSelector, setFrameSizeSelector] =
    useState<FrameSizeSelector>('desktop');
  const [hostInfo, setHostInfo] = useState<HostInfo | null>(null);

  const sendMessage = useMessaging(message => {
    if (message.type === 'frameSizeChange') {
      setFrameSizeSelector(message.payload);
    }

    if (message.type === 'sendConsent') {
      const serializedConsent = serializeConsentOptions(
        message.payload as ConsentParams,
      );
      setConsentParams(serializedConsent);
    }

    if (message.type === 'overwriteConfig') {
      // Only needed for TS, the config object should be there.
      const newConfig =
        window.__DH_CONFIG != null
          ? (mergeDeepRight<WindowConfig, Partial<WindowConfig>>(
              window.__DH_CONFIG,
              message.payload,
            ) as WindowConfig)
          : window.__DH_CONFIG;

      // Also set on window
      window.__DH_CONFIG = newConfig;
      setWidgetContext(
        windowConfigToWidgetContext({
          ...widgetContext,
          windowConfig: newConfig,
          hostPageParams: widgetContext?.hostPageParams,
          consentParams,
        }),
      );
    }
    if (message.type === 'hostInfo') {
      setHostInfo(message.payload);
    }
  });

  useEffect(() => {
    const search = global.window.location.search;
    const { dhFrameSize, preventDefaultOpening } = qs.parse(search);
    const _frameSizeSelector: FrameSizeSelector =
      typeof dhFrameSize === 'string'
        ? (dhFrameSize as FrameSizeSelector)
        : 'desktop';

    const _preventDefaultOpening: boolean =
      typeof preventDefaultOpening === 'string'
        ? Boolean(parseInt(preventDefaultOpening))
        : false;

    setFrameSizeSelector(_frameSizeSelector ?? 'desktop');
    setWidgetContext(
      windowConfigToWidgetContext({
        windowConfig: window.__DH_CONFIG,
        hostPageParams: search,
        preventDefaultOpening: _preventDefaultOpening,
        consentParams,
      }),
    );

    // eslint-disable-next-line no-console
    if (window.__DH_CONFIG?.apps.length === 0 && console && console.warn) {
      // eslint-disable-next-line no-console
      console.warn(
        `[DatHuis Widget] No apps enabled, please enable some apps.`,
      );
    }
  }, []);

  // Watch for consent updates
  useEffect(() => {
    setWidgetContext(prev => {
      if (prev !== null) {
        return { ...prev, consentParams };
      }

      return prev;
    });
  }, [consentParams]);

  useEffect(() => {
    if (widgetContext !== null) {
      sendMessage({
        type: 'configLoaded',
        payload: window.__DH_CONFIG,
      });
    }
  }, [sendMessage, widgetContext]);

  if (widgetContext === null || Object.keys(widgetContext?.apps).length === 0)
    return null;

  const ga4Id = widgetContext?.googleAnalytics4;

  return (
    <FrameSizeContext.Provider value={frameSizeSelector}>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0"
        />
      </Head>
      <HostInfoContext.Provider value={hostInfo}>
        <WidgetContext.Provider value={widgetContext}>
          <>
            <App {...props} />
            {children}
            {/* Load Google Analytics script */}
            <Script
              id="loadGtag"
              strategy="afterInteractive"
              src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GA_ID}`}
            />
            <Script id="initGA" strategy="afterInteractive">
              {`
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                
                // Push the default consent state to the dataLayer to ensure GTM recognizes it if needed
                  dataLayer.push({
                    event: "gtm.init_consent",
                    ad_storage: "denied",
                    analytics_storage: "denied",
                    functionality_storage: "denied",
                    personalization_storage: "denied"
                  });

                gtag('js', new Date());

                // Set default consent options before config is initialized
                  gtag('consent', 'default', {
                    ad_storage: 'denied',
                    analytics_storage: 'denied',
                    functionality_storage: 'denied',
                    personalization_storage: 'denied',
                  }, '${process.env.NEXT_PUBLIC_GA_ID}');

                  ${
                    ga4Id
                      ? `
                        gtag('consent', 'default', {
                          ad_storage: 'denied',
                          analytics_storage: 'denied',
                          functionality_storage: 'denied',
                          personalization_storage: 'denied',
                         }, '${ga4Id}');
                        `
                      : ''
                  }
        `}
            </Script>
          </>
        </WidgetContext.Provider>
      </HostInfoContext.Provider>
    </FrameSizeContext.Provider>
  );
};

export default WithConfig;
