import React from 'react';
import styled, { css } from 'styled-components';
import illustrations from '../../../../../../../Illustration/components';
import JustificationContainer from '../../../../../../../JustificationContainer';
import { Heading2, Heading4 } from '../../../../../../../Typography';
import Illustration from '../../../../../../../Illustration';

export type Props = {
  dataTestId?: string;
  header: React.ReactNode;
  description?: React.ReactNode;
  illustration?: keyof typeof illustrations;
  // icon: { src: string };
} & React.HtmlHTMLAttributes<HTMLDivElement>;

const ILLUSTRATION_SIZE = 64;
const HighlightedHeader: React.FC<Props> = ({
  dataTestId,
  header,
  description,
  illustration,
  ...rest
}) => (
  <Container data-testid={dataTestId} {...rest}>
    <JustificationContainer align="center">
      <StyledIllustration name={illustration || 'bb-wc'} />
      <JustificationContainer
        direction="column"
        margin={[null, null, null, 'base']}
      >
        <StyledHeading2>{header}</StyledHeading2>
        {description && (
          <StyledHeading4 withoutMargin>{description}</StyledHeading4>
        )}
      </JustificationContainer>
    </JustificationContainer>
  </Container>
);

const Container = styled.div<{}>(
  ({ theme }) => css`
    flex: 1;
    background-color: ${theme.color('primary')};
    padding: ${theme.space('m')};
    border-radius: ${theme.getSystem().border.radius.base}
      ${theme.getSystem().border.radius.base} 0 0;
  `,
);

const StyledHeading2 = styled(Heading2)<{}>(
  ({ theme }) => css`
    color: ${theme.color('primary', 'text')};
  `,
);

const StyledHeading4 = styled(Heading4)<{}>(
  ({ theme }) => css`
    color: ${theme.color('primary', 'text')};
  `,
);

const StyledIllustration = styled(Illustration)<{}>(
  ({ theme }) => css`
    svg {
      height: ${ILLUSTRATION_SIZE}px;
      width: ${ILLUSTRATION_SIZE}px;
      color: ${theme.color('primary', 'text')};
    }
  `,
);

export default HighlightedHeader;
