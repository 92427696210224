import React from 'react';
import { Props } from '..';

const BBWC: React.FC<Props> = ({ color, ...props }) => (
  <svg
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M38.4 47.258V40.3247H35.2889V44.058L31.5556 40.3247C31.2 39.9692 30.8444 39.9692 30.5778 40.3247L20 50.7247H23.1111V60.4136H29.1556H32.8889H38.8444V50.7247H41.8667L38.4 47.258Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M38.6663 27.3469L36.3552 26.3691C35.4663 26.0136 35.1108 25.0358 35.4663 24.1469L37.7774 18.7247C38.133 17.8358 39.1108 17.4803 39.9997 17.8358L42.3108 18.8136M48.3552 19.6136L49.6886 16.5025C50.2219 15.258 49.5997 13.7469 48.3552 13.2136L42.3108 18.8136L38.7552 27.258L47.4663 30.9025C48.2663 31.258 49.1552 30.9914 49.5997 30.2803L53.5997 23.7914C54.0441 22.9914 53.8663 22.0136 53.0663 21.4803C52.9774 21.3914 52.8886 21.3914 52.7997 21.3914L48.3552 19.6136Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M63.0635 15.0707L37.9277 4.44444L28.0283 27.8609L53.1641 38.4871L63.0635 15.0707Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.0222 13.8358L21.4222 13.1247C22.4888 12.7692 23.5555 13.3025 23.9999 14.2803L25.8666 20.5025C26.0444 21.5692 25.4222 22.6358 24.3555 22.9025L21.9555 23.5247M16.3555 27.1692L17.4222 30.7247C17.8666 32.1469 19.4666 33.0358 20.8888 32.5914L21.9555 23.5247L18.9333 13.8358L8.97772 16.858C8.08883 17.1247 7.5555 18.0136 7.64439 18.9025L8.88883 27.258C9.06661 28.2358 9.9555 28.9469 11.0222 28.858C11.1111 28.858 11.1999 28.858 11.2888 28.7692L16.3555 27.1692Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M34.0442 30.5469L34.3998 31.8803L8.35534 39.8803L0.888672 15.5247L26.9331 7.5247L31.022 20.6803"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default BBWC;
