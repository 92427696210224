import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { setContext, configureScope } from '@sentry/nextjs';
import styled, { css } from 'styled-components';
import { animated, useTransition } from 'react-spring';
import useMessaging from '../../hooks/useMessaging';
import log from '../../utils/log';
import TriggerComponent from '../TriggerComponent';
import useWidgetContext from '../../hooks/useWidgetContext';
import { iframeSizes } from '../../shared/constants';
import {
  PostMessage,
  Position,
  LayoutT,
  ResizeMessage,
  Appearance,
} from '../../shared/types';
import useDismiss from '../../hooks/useDismiss';
import Footer from '../Footer';
import Header, { HeaderAction } from '../Header';
import getCurrentLayout from './utils/getCurrentLayout';
import useCopy from '../../hooks/useCopy';
import usePathPrefix from '../../hooks/usePathPrefix';

import useFireTrackingEvent from '../../hooks/useFireTrackingEvent';
import { ROOT } from '../../constants/paths';
import useDHRouter from '../../hooks/useDHRouter';
import { isNil } from 'ramda';
import cleanPath from '../../utils/cleanPath';
import useStateContext from '../../hooks/useStateContext';
import { CLOSE_BUTTON_SIZE } from '../TriggerComponent/components/Large/Variants/Initial/components/CloseButton';
import isMobile from '../../utils/isMobile';
import useFrameSizeSelector from '../../hooks/useFrameSizeSelector';

export type Props = {
  children: React.ReactNode;
};

const Layout: React.FC<Props> = ({ children, ...rest }) => {
  const dhRouter = useDHRouter();
  const pathPrefix = usePathPrefix();
  const fireTrackingEvent = useFireTrackingEvent();
  const context = useWidgetContext();
  const { appsOpened } = useStateContext();
  const { highlighted, positions, apps, appearances: sizes } = context;
  const [expanded, setExpanded] = useState<boolean>(false);
  const { actions: actionLabels } = useCopy();
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
  const [isDismissed, onDismiss, onClearDismiss] = useDismiss();
  const frameSizeSelector = useFrameSizeSelector();

  // We always want to load small on mobile so we don't take up the entire screen.
  const currentTriggerAppearance = isMobile()
    ? Appearance.Small
    : sizes[frameSizeSelector];

  const containerRef = useRef<HTMLDivElement | null>(null);

  const root = pathPrefix + ROOT;

  const onMessage = useCallback(
    (message: PostMessage) => {
      log('Received message in widget', message);

      switch (message.type) {
        case 'open':
          onClearDismiss();
          setExpanded(true);
          let pathname = `${pathPrefix}${message.payload.path ?? ROOT}`;

          const matched = message.payload.path?.match(/^\/apps\/(.+)/);
          // If the app is not inside the app context its not enabled we we redirect to not found page
          if (matched && matched[1] && isNil(context.apps[matched[1]])) {
            pathname = `${pathPrefix}/not-found`;
          }

          dhRouter.push(
            {
              pathname: pathname,
              query: { queryParams: message.payload.queryParams },
            },
            pathname,
          );

          const eventName = (():
            | 'DeepLink'
            | 'ExpandWidget'
            | 'OpenCommand' => {
            if (message.payload.triggerType === 'deepLink') return 'DeepLink';
            if (message.payload.triggerType === 'command') return 'OpenCommand';
            return 'ExpandWidget';
          })();

          fireTrackingEvent({
            event: eventName,
            path: message.payload.path ?? ROOT,
            triggerType: message.payload.triggerType,
          });
          break;
        case 'close':
          setExpanded(false);
          dhRouter.push(root);
          fireTrackingEvent({ event: 'CollapseWidget' });
          break;
        case 'track': {
          const app =
            dhRouter.query.slug && typeof dhRouter.query.slug === 'string'
              ? context.apps[dhRouter.query.slug]
              : null;

          if (app) {
            fireTrackingEvent({
              event: 'TrackMessage',
              message,
            });
          }
          break;
        }
        case 'clearDismiss':
          onClearDismiss();
          dhRouter.push(root);
          break;

        // Forward message up to bootstrap and external window
        case 'iframeBridgeLoaded':
          sendMessage({ type: 'iframeBridgeLoaded' });
          break;
        default:
          break;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fireTrackingEvent, onClearDismiss, pathPrefix, root, dhRouter],
  );

  const sendMessage = useMessaging(onMessage);

  const layoutType = getCurrentLayout({
    isFullScreen,
    router: dhRouter,
    expanded,
  });

  const hasOnlyOneApp = useMemo(() => Object.keys(apps).length === 1, [apps]);
  const currentApp =
    dhRouter.query.slug && typeof dhRouter.query.slug === 'string'
      ? context.apps[dhRouter.query.slug]
      : null;

  useEffect(() => {
    // When the widget has been dismissed we don't want to fire any resize events
    if (isDismissed) return;

    const collapsedFrame = iframeSizes.collapsed;

    const collapsedFrameAppearance =
      context.preventDefaultOpening === true
        ? iframeSizes.hidden[frameSizeSelector]
        : collapsedFrame[currentTriggerAppearance][frameSizeSelector];

    let resizePayload: ResizeMessage['payload'] = {
      height: '0',
      width: '0',
      layout: layoutType,
      position: positions[frameSizeSelector],
      fullscreen: isFullScreen,
    };

    if (frameSizeSelector === 'mobile') {
      const additionalSpace = hasOnlyOneApp ? '10px' : '0px';

      const expectations =
        expanded && layoutType !== 'collapsed'
          ? {
              ...iframeSizes[layoutType][frameSizeSelector],
            }
          : {
              width:
                currentTriggerAppearance === Appearance.Large
                  ? `calc(${collapsedFrameAppearance.width})`
                  : `calc(${collapsedFrameAppearance.width} + ${CLOSE_BUTTON_SIZE}px)`,
              height:
                currentTriggerAppearance === Appearance.Large
                  ? `calc(${collapsedFrameAppearance.height} + ${additionalSpace})`
                  : `calc(${collapsedFrameAppearance.height} + ${CLOSE_BUTTON_SIZE}px)`,
            };

      resizePayload = { ...resizePayload, ...expectations };
    } else {
      const additionalSpace =
        currentTriggerAppearance === Appearance.Small
          ? `${CLOSE_BUTTON_SIZE}px`
          : hasOnlyOneApp
          ? '0px'
          : '16px';

      const expectations =
        expanded && layoutType !== 'collapsed'
          ? {
              width: `calc(${
                iframeSizes[layoutType][frameSizeSelector].width
              } - ${layoutType === 'wide' ? CLOSE_BUTTON_SIZE : 0}px)`,
              height: `calc(${
                iframeSizes[layoutType][frameSizeSelector].height
              } - ${layoutType === 'wide' ? CLOSE_BUTTON_SIZE : 0}px)`,
              position: positions[frameSizeSelector],
              fullscreen: isFullScreen,
              layout: layoutType,
            }
          : {
              width: `calc(${collapsedFrameAppearance.width} + ${additionalSpace})`,
              height: `calc(${collapsedFrameAppearance.height} + ${additionalSpace})`,
              position: positions[frameSizeSelector],
              layout: layoutType,
            };

      resizePayload = { ...resizePayload, ...expectations };
    }

    sendMessage({
      type: 'resize',
      payload: resizePayload,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isFullScreen,
    layoutType,
    sendMessage,
    expanded,
    frameSizeSelector,
    positions,
    currentTriggerAppearance,
    hasOnlyOneApp,
    isDismissed,
    frameSizeSelector,
  ]);

  useEffect(() => {
    sendMessage({
      type: 'widgetReady',
      payload: {},
    });
  }, [sendMessage]);

  useEffect(() => {
    setContext('widget-context', {
      ...context,
    });

    return () => {
      // When we unmount the layout we need to clear the scope
      // https://docs.sentry.io/platforms/javascript/guides/nextjs/enriching-events/context/#clearing-context
      configureScope(scope => scope.clear());
    };
  }, [context]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape' && layoutType === 'fullscreen') {
        setIsFullScreen(false);
      }
    },
    [layoutType],
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      const _url = url.split('?')[0]; // location.pathname doesn't include the query params therefore we'll need to remove these first
      // Only fire when route actually changed
      if (_url !== window.location.pathname) {
        const cleanedPath = cleanPath(url);
        fireTrackingEvent({
          event: 'VirtualPageView',
          path: cleanedPath,
        });
      }
    };
    dhRouter.events.on('routeChangeStart', handleRouteChange);

    return () => {
      dhRouter.events.off('routeChangeStart', handleRouteChange);
    };
  }, [dhRouter, fireTrackingEvent]);

  const transitions = useTransition(expanded, {
    from: { height: '0%', opacity: 0 },
    enter: {
      height: '100%',
      opacity: 1,
    },
    leave: { height: '0%', opacity: 0 },
    config: {
      tension: 180,
      mass: 1,
      friction: 10,
      clamp: true,
    },
  });

  const toggleFullScreen = useCallback(() => {
    setIsFullScreen(prev => !prev);
  }, []);

  const actions: Array<HeaderAction> = [
    {
      label: actionLabels.goBackToOverview,
      icon: 'arrow-left',
      onClick: () => {
        setIsFullScreen(false);

        if (hasOnlyOneApp) {
          setExpanded(false);
        }
        dhRouter.replace(root);
      },
    },
  ];

  if (frameSizeSelector !== 'mobile') {
    actions.push({
      label: actionLabels.fullscreen,
      icon: 'fullscreen',
      onClick: toggleFullScreen,
    });
  }

  const onClose = useCallback(() => {
    setExpanded(false);
    sendMessage({
      type: 'resize',
      payload: {
        ...iframeSizes.collapsed[currentTriggerAppearance][frameSizeSelector],
        position: positions[frameSizeSelector],
        layout: layoutType,
      },
    });

    fireTrackingEvent({
      event: 'CollapseWidget',
    });

    dhRouter.replace(root);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (
    isDismissed === true ||
    (context.preventDefaultOpening === true && expanded === false)
  ) {
    return null;
  }

  return (
    <Container ref={containerRef}>
      {transitions(
        (styles, expanded) =>
          expanded === true && (
            <Overlay
              {...rest}
              layout={layoutType}
              $position={positions[frameSizeSelector]}
            >
              <WidgetContainer layout={layoutType} style={styles}>
                <Header
                  appearance={layoutType}
                  onClose={onClose}
                  actions={actions}
                />
                <Inner
                  $bypassResizer={currentApp ? currentApp.bypassResizer : false}
                >
                  {children}
                </Inner>
                <Footer />
              </WidgetContainer>
            </Overlay>
          ),
      )}
      {!expanded && (
        <TriggerComponent
          size={currentTriggerAppearance}
          position={positions[frameSizeSelector]}
          highlight={highlighted}
          onDismiss={onDismiss}
          hasOnlyOneApp={hasOnlyOneApp}
          onTrigger={path => {
            const nextPath = `${pathPrefix}${path}`;
            const cleanedPath = cleanPath(nextPath);
            if (cleanedPath !== ROOT) {
              appsOpened.setValue(prev => {
                if (!prev.includes(cleanedPath)) {
                  return [...prev, cleanedPath];
                }
                return prev;
              });
            }
            if (nextPath !== dhRouter.asPath) {
              dhRouter.replace(`${pathPrefix}${path}`);
            }

            setExpanded(true);

            sendMessage({
              type: 'resize',
              payload: {
                ...iframeSizes.narrow[frameSizeSelector],
                position: positions[frameSizeSelector],
                layout: layoutType,
              },
            });
          }}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  height: 100%;
`;

const Overlay = styled.div<{
  layout: LayoutT;
  $position: Position;
}>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;
  align-self: flex-start;
`;

export const scrollBarStyles = css`
  &::-webkit-scrollbar {
    width: 0.5em;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.color('secondary')};
    border-radius: ${({ theme }) => theme.getSystem().border.radius.base};
    outline: none;
  }
`;

const WidgetContainer = styled(animated.div)<{ layout: LayoutT; style: any }>(
  ({ theme }) => css`
    background-color: #fff;
    border-radius: ${theme.getSystem().border.radius.base};
    box-shadow: ${theme.boxShadow('s')};
    width: 100%;

    position: relative;

    display: flex;
    flex-direction: column;
  `,
);

const Inner = styled.div<{ $bypassResizer?: boolean }>(
  ({ $bypassResizer }) => css`
    height: 100%;
    width: 100%;

    overflow-y: ${$bypassResizer ? 'auto' : 'scroll'};

    ${scrollBarStyles}
  `,
);

export default Layout;
