import type { ConsentParams } from '../../context/WidgetContext';

export const defaultConsentOptions: ConsentParams = {
  ad_storage: 'denied',
  ad_user_data: 'denied',
  ad_personalization: 'denied',
  analytics_storage: 'denied',
};

const serializeConsentOptions = (options?: ConsentParams): ConsentParams => {
  if (!options) return defaultConsentOptions;

  const serialized = (
    Object.keys(defaultConsentOptions) as (keyof ConsentParams)[]
  ).reduce((acc, key) => {
    acc[key] =
      options[key] === 'granted' || options[key] === 'denied'
        ? options[key]
        : 'denied';
    return acc;
  }, {} as ConsentParams);

  return serialized;
};

export default serializeConsentOptions;
