const gtag = (...args: any) => {
  if (typeof window !== 'undefined') {
    if (typeof window.gtag === 'undefined') {
      window.dataLayer = window.dataLayer || [];
      window.gtag = function gtag() {
        window.dataLayer.push(arguments);
      };
    }

    // @ts-ignore
    window.gtag(...args);
  }
};

export default gtag;
