import { Locale } from '../../../../shared/types';

export const getStorageKey = (accountId: string): string =>
  `dh_uw_${accountId}_locale`;
const getStoredLocale = (accountId: string): Locale | null => {
  try {
    const locale = localStorage.getItem(getStorageKey(accountId));
    if (locale === null) return null;

    return locale as Locale;
  } catch {
    return null;
  }
};
export default getStoredLocale;
