import { isNil, sort } from 'ramda';
import { DHApp, IframeApp, WindowIframeApp } from '../../shared/types';
import take3 from '../take3';
/**
 * Sorts an array of DHApp objects based on their pinned weight.
 * @template T - The type of DHApp object in the array.
 * @param {Array<T>} apps - The array of DHApp objects to sort.
 * @returns {Array<T>} - The sorted array of DHApp objects.
 */
export const sortPinned = <T extends DHApp>(apps: Array<T>): Array<T> =>
  sort(
    ({ pinned: aPinned }, { pinned: bPinned }) =>
      (bPinned?.weight ?? 0) - (aPinned?.weight ?? 0),
    apps,
  );

const WEIGHT_BASE = 100;
const WEIGHT_MAX = 500;

/**
 * Retrieves an array of pinned apps and sorts them by their pinned weight.
 *
 * @param {Array<DHApp>} apps - An array of DHApp objects sorted
 * @returns {Array<WindowIframeApp>} - An array of DHApp objects sorted by their pinned weight.
 */
const getPinnedApps = (apps: Array<DHApp>): Array<WindowIframeApp> => {
  // Filter the apps array to only include pinned apps with enabled set to true
  const pinnedApps = sortPinned(
    take3(apps.filter(({ pinned }) => pinned && pinned.enabled === true)),
  );

  const notPinnedApps = apps.filter(
    ({ pinned }) => !pinned || pinned.enabled !== true,
  );

  const top3WithAllSlotsFilled = ((): Array<IframeApp> => {
    const arrWithPositions: Array<IframeApp | null> = [null, null, null];
    pinnedApps.forEach(app => {
      // Only to guard TS
      if (app.pinned) {
        const index = (WEIGHT_MAX - app.pinned.weight) / WEIGHT_BASE - 1; // Highest value is 400 === position 1, so subtract 2 to get to index 0
        if (index >= 0) {
          arrWithPositions[index] = app;
        }
      }
    });

    const copyNotPinned = [...notPinnedApps];
    const filledEmptySlotsWithTopSorted = arrWithPositions.map(
      (possibleApp, _index) => {
        if (possibleApp !== null) {
          return possibleApp;
        }

        const appToReplaceWith = copyNotPinned[0];

        copyNotPinned.shift();

        return appToReplaceWith;
      },
    );

    return filledEmptySlotsWithTopSorted;
  })();

  return top3WithAllSlotsFilled.filter(
    app => !isNil(app),
  ) as Array<WindowIframeApp>;
};

export default getPinnedApps;
