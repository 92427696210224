import React from 'react';
import styled from 'styled-components';

export type Props = {
  dataTestId?: string;
  src: string;
};

const Logo: React.FC<Props> = ({ dataTestId, src, ...rest }) => (
  <LogoImage src={src} data-testid={dataTestId} {...rest} />
);

const MAX_HEIGHT = 50;
const MAX_WIDTH = 155;
const LogoImage = styled.img`
  max-width: ${MAX_WIDTH}px;
  max-height: ${MAX_HEIGHT}px;
  display: block;
  width: auto;
  height: max-content;
`;

export default Logo;
