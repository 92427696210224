import React, { useEffect, useState } from 'react';
import StateContext from '../../context/StateContext';
import useWidgetContext from '../../hooks/useWidgetContext';
import { Locale } from '../../shared/types';
import getStoredLocale, { getStorageKey } from './utils/getStoredLocale';
import useFireTrackingEvent from '../../hooks/useFireTrackingEvent';
import getBrowserLocale from '../../utils/getBrowserLocale';
import useMessaging from '../../hooks/useMessaging';

export type Props = {
  dataTestId?: string;
  children: React.ReactNode;
};

const StateProvider: React.FC<Props> = ({ children }) => {
  const { accountId } = useWidgetContext();
  const fireTrackingEvent = useFireTrackingEvent();
  const [appsOpened, setAppsOpened] = useState<Array<string>>([]);
  const [reportedAppsOpened, setReportedAppsOpened] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [title, setTitle] = useState<string | null>(null);
  const [locale, setLocale] = useState<Locale>(
    getStoredLocale(accountId) ?? getBrowserLocale(),
  );

  useMessaging(message => {
    if (
      message.type === 'locale' &&
      (message.payload === 'en' || message.payload === 'nl')
    ) {
      setLocale(message.payload);
    }
  });

  useEffect(() => {
    try {
      const storageKey = getStorageKey(accountId);
      localStorage.setItem(storageKey, locale);
    } catch {
      // No catch nothing we can do about it.
    }

    // Only run this effect when the locale changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  useEffect(() => {
    if (appsOpened.length !== 0 && appsOpened.length > reportedAppsOpened) {
      fireTrackingEvent({
        event: 'AppsOpened',
        amount: appsOpened.length,
      });
      setReportedAppsOpened(appsOpened.length);
    }
  }, [appsOpened, fireTrackingEvent, reportedAppsOpened]);

  return (
    <StateContext.Provider
      value={{
        appsOpened: {
          value: appsOpened,
          setValue: setAppsOpened,
        },
        loading: {
          value: loading,
          setValue: setLoading,
        },
        title: {
          value: title,
          setValue: setTitle,
        },
        locale: {
          value: locale,
          setValue: setLocale,
        },
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export default StateProvider;
