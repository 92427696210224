import React from 'react';
import styled from 'styled-components';
import icons from './components';

export type Props = React.HTMLAttributes<HTMLSpanElement> & {
  name: keyof typeof icons;
  color?: string;
  className?: string;
  strokeWidth?: number | string;
};

const Icon = ({ name, color, strokeWidth, ...props }: Props) => {
  const PickedIcon = icons[name];

  return (
    <Container {...props} role="img">
      <PickedIcon color={color || 'currentColor'} strokeWidth={strokeWidth} />
    </Container>
  );
};

const Container = styled.span`
  svg {
    width: 100%;
  }
`;

export default Icon;
