import { useContext } from 'react';
import { FeatureFlag } from '../../shared/types';
import useWidgetContext from '../useWidgetContext';
import TrackingContext from '../../context/TrackingContext';

const useDHFlags = (): Array<FeatureFlag> => {
  const { featureFlags } = useWidgetContext();
  const { accountId, sessionId } = useContext(TrackingContext);

  const sessionNumber = generateNumberFromString(`${sessionId}-${accountId}`, {
    min: 0,
    max: 10,
  });

  const activeFlags = featureFlags.filter(
    _flag => sessionNumber < _flag.percentage,
  );

  return activeFlags;
};

const fnvHash = (str: string): number => {
  let hash = 2166136261; // FNV offset basis
  const prime = 16777619; // FNV prime

  for (let i = 0; i < str.length; i++) {
    hash ^= str.charCodeAt(i);
    hash *= prime;
  }

  // Ensure the result is between 0 and 10
  return (hash >>> 0) % 11;
};

const generateNumberFromString = (
  inputString: string,
  opt: {
    min: number;
    max: number;
  },
): number => {
  if (opt.min >= opt.max) {
    throw new Error('Minimum value must be less than the maximum value.');
  }

  const range = opt.max - opt.min;
  const randomNumber = (fnvHash(inputString) % range) + opt.min;
  return randomNumber;
};

export default useDHFlags;
