import { Locale } from '../../shared/types';

const getBrowserLocale = (): Locale => {
  try {
    if (navigator && 'language' in navigator) {
      const browserLanguage = navigator.language;
      return browserLanguage.startsWith('nl') ? 'nl' : 'en';
    }
    return 'nl';
  } catch {
    return 'nl';
  }
};
export default getBrowserLocale;
