import { useContext } from 'react';
import WidgetContext, { IWidgetContext } from '../../context/WidgetContext';

const useWidgetContext: () => IWidgetContext = () => {
  const context = useContext<IWidgetContext | null>(WidgetContext);

  if (context === null) {
    throw new Error(
      'No widget context set yet, set it before continuing with the app',
    );
  }

  return context;
};

export default useWidgetContext;
