import { mobileBreakpoint } from '../../../shared/constants';

const breakpoints = {
  mobile: `${mobileBreakpoint}px`,
  tablet: '768px',
  tabletLandscape: '992px',
  desktop: '1200px',
};

export type SystemBreakpointMap = typeof breakpoints;
export type SystemBreakpoint = keyof SystemBreakpointMap;
export default breakpoints;
