import { isNil } from 'ramda';
import { TrackingInterface } from '../..';

import getEventName from '../../utils/getEventName';
import { TrackingInfo } from '../../../../shared/types';
import getEventNameForApp from '../../utils/getEventNameForApp';

type EventBody = {
  /**
   * userId would be across "widget instances"
   */
  userId: string;

  /**
   * accountId needs no explanation
   */
  accountId: string;

  /**
   * sessionId is session storage based
   */
  sessionId: string;

  /**
   * runtimeId should be this current run, so different tabs would have different runtimeIds
   */
  runtimeId: string;

  /**
   * Name of the event
   */
  eventName: string;

  /**
   * Any additional information we want to provide to the event
   */
  eventAddition?: string;

  /**
   * What feature flags are active?
   */
  featureFlags: Array<string>;

  /**
   * When was this event fired
   */
  timestamp: string; // ISO string
};

const track = async ({
  endpoint,
  eventData,
}: {
  endpoint?: TrackingInfo;
  eventData: EventBody;
}) => {
  if (!endpoint) return;

  try {
    void fetch(endpoint.url, {
      method: 'POST',
      headers: {
        'x-api-key': endpoint.key,
      },
      body: JSON.stringify(eventData),
    }).catch(() => {
      // Do nothing, fail gracefully
    });
  } catch {
    // Do nothing, fail gracefully
  }
};

const DatHuisTracking: TrackingInterface = {
  onPageView: ({ currentApp, tracking }) => {
    if (!tracking) return;
    const { endpoint, ...trackingInfo } = tracking;

    const now = new Date().toISOString();

    if (!isNil(currentApp)) {
      return track({
        endpoint,
        eventData: {
          eventName: 'app_opened',
          eventAddition: currentApp.slug,
          timestamp: now,
          ...trackingInfo,
        },
      });
    }

    return track({
      endpoint,
      eventData: {
        eventName: 'page_view',
        timestamp: now,
        ...trackingInfo,
      },
    });
  },
  onCollapseWidget: ({ tracking }) => {
    if (!tracking) return;
    const { endpoint, ...trackingInfo } = tracking;

    return track({
      endpoint,
      eventData: {
        eventName: getEventName('e_collapse_wdgt'),
        timestamp: new Date().toISOString(),
        ...trackingInfo,
      },
    });
  },
  onExpandWidget: ({ event, tracking }) => {
    if (!tracking) return;
    const { endpoint, ...trackingInfo } = tracking;

    return track({
      endpoint,
      eventData: {
        eventName: getEventName('e_expand_wdgt'),
        eventAddition: `triggerType: ${event.triggerType}`,
        timestamp: new Date().toISOString(),
        ...trackingInfo,
      },
    });
  },
  onDeepLink: ({ event, tracking }) => {
    if (!tracking) return;
    const { endpoint, ...trackingInfo } = tracking;

    return track({
      endpoint,
      eventData: {
        eventName: getEventName('e_expand_wdgt'),
        eventAddition: `triggerType: ${event.triggerType}`,
        timestamp: new Date().toISOString(),
        ...trackingInfo,
      },
    });
  },
  onOpenByCommand: ({ event, tracking }) => {
    if (!tracking) return;
    const { endpoint, ...trackingInfo } = tracking;
    return track({
      endpoint,
      eventData: {
        eventName: getEventName('e_open_cmd'),
        eventAddition: `triggerType: ${event.triggerType}`,
        timestamp: new Date().toISOString(),
        ...trackingInfo,
      },
    });
  },
  onDismissWidget: ({ tracking }) => {
    if (!tracking) return;
    const { endpoint, ...trackingInfo } = tracking;
    return track({
      endpoint,
      eventData: {
        eventName: getEventName('e_dismiss_wdgt'),
        timestamp: new Date().toISOString(),
        ...trackingInfo,
      },
    });
  },
  onAppsOpened: ({ event, tracking }) => {
    if (!tracking) return;
    const { endpoint, ...trackingInfo } = tracking;
    return track({
      endpoint,
      eventData: {
        eventName: getEventName(`e_opened_${event.amount}_apps`),
        timestamp: new Date().toISOString(),
        ...trackingInfo,
      },
    });
  },
  onSessionStart: ({ tracking }) => {
    if (!tracking) return;
    const { endpoint, ...trackingInfo } = tracking;
    return track({
      endpoint,
      eventData: {
        eventName: getEventName('session_start'),
        timestamp: new Date().toISOString(),
        ...trackingInfo,
      },
    });
  },
  onTrackMessage: ({ tracking, event, currentApp }) => {
    if (!tracking || !currentApp) return;
    const { endpoint, ...trackingInfo } = tracking;
    return track({
      endpoint,
      eventData: {
        eventName: getEventNameForApp(
          `${currentApp.analyticsPrefix}.${event.message.payload.name}`,
        ),
        eventAddition: `conversion: ${
          event.message.payload.params.conversion === true
        }`,
        timestamp: new Date().toISOString(),
        ...trackingInfo,
      },
    });
  },
};

export default DatHuisTracking;
