import illustrations from '../../components/Illustration/components';
import { Locale } from '../../shared/types';
import useLocale from '../useLocale';

type CTA = {
  illustration: keyof typeof illustrations;
  description: React.ReactNode;
  title: React.ReactNode;
  next: string;
};

type Copy = {
  trigger: {
    footer: string;
  };
  serviceList: {
    apps: string;
  };

  landing: {
    [key: string]: CTA;
  };

  footer: string;

  actions: {
    goBackToOverview: string;
    fullscreen: string;
  };

  services: {
    loading: string;
  };
};

const copy: Record<Locale, Copy> = Object.freeze({
  nl: {
    trigger: {
      footer: 'Bekijk ook onze andere handige tools',
    },
    serviceList: {
      apps: 'Wat wil je doen?',
    },
    landing: {
      cta: {
        illustration: 'interest-term',
        title: 'Heb jij al een hypotheekadviseur?',
        description:
          'Duis ullamcorper turpis nec orci viverra, eget varius neque mattis. Duis ullamcorper turpis nec orci viverra, eget varius neque mattis.',
        next: '#',
      },
    },

    footer: 'Deze tools worden aangeboden in samenwerking met',
    actions: {
      goBackToOverview: 'Terug naar overzicht',
      fullscreen: 'Volledig scherm',
    },
    services: {
      loading: 'Laden van de ervaring...',
    },
  },
  en: {
    trigger: {
      footer: 'Buying or selling a house? View our other handy tools',
    },
    serviceList: {
      apps: 'What do you want to do?',
    },

    landing: {
      cta: {
        illustration: 'interest-term',
        title: 'Do you already have a mortgage advisor?',
        description:
          'Duis ullamcorper turpis nec orci viverra, eget varius neque mattis. Duis ullamcorper turpis nec orci viverra, eget varius neque mattis.',
        next: '#',
      },
    },
    footer: 'These tools are offered in collaboration with',
    actions: {
      goBackToOverview: 'Go back to overview',
      fullscreen: 'Fullscreen',
    },
    services: {
      loading: 'Loading the experience...',
    },
  },
});

const useCopy = () => {
  const locale = useLocale();
  return copy[locale];
};

export default useCopy;
