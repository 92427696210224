import React from 'react';
import { LayoutT } from '../../shared/types';
import icons from '../Icon/components';
import Narrow from './components/Narrow';
import Wide from './components/Wide';

export type HeaderAction = {
  label: React.ReactNode;
  icon: keyof typeof icons;
  onClick: (event: React.MouseEvent<Element, MouseEvent>) => void;
};

type Props = {
  dataTestId?: string;
  appearance: LayoutT;
  actions?: Array<HeaderAction>;
  onClose?: () => void;
};

const Header: React.FC<Props> = ({ actions, appearance, onClose }) => {
  switch (appearance) {
    case 'fullscreen':
    case 'wide':
      return <Wide actions={actions} />;
    case 'narrow':
    default:
      return <Narrow onClose={onClose} />;
  }
};

export default Header;
