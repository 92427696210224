/**
 * If dismissing the widget has timed out returns true otherwise false
 * @param {string} dismissedTime - Time that it was dismissed
 * keywords: snooze, dismiss, time
 */

// 12 hours
const DISMISS_TIMEOUT = 1000 * 60 * 60 * 12;

const shouldDismiss = (dismissedTime: string | null): boolean => {
  if (dismissedTime === null) return false;

  const dismissTime = parseInt(dismissedTime);

  return DISMISS_TIMEOUT > Date.now() - dismissTime;
};

export default shouldDismiss;
