import * as React from 'react';
import { Props } from '..';

const ChevronDown: React.FC<Props> = ({
  color = 'currentColor',
  strokeWidth = 2.044,
  ...props
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={strokeWidth}
      d="M1.1 6.6L12 17.5 22.9 6.6"
    />
  </svg>
);

export default ChevronDown;
