import { useEffect, useRef } from 'react';
import { IWidgetContext } from '../../context/WidgetContext';
import { isNil } from 'ramda';

import gtag from '../../utils/gtag';
import useDHFlags from '../useDHFlags';
import useWidgetContext from '../useWidgetContext';
import serializeConsentOptions from '../../utils/serializeConsentOptions';

// https://www.simoahava.com/analytics/cookieflags-field-google-analytics/
const COOKIE_FLAGS = 'max-age=7200;secure;samesite=none';

const VERSION = 'v1';

export const useDefaultEventOptions = () => {
  const flags = useDHFlags();
  const widgetContext = useWidgetContext();

  const anyFlagPotential = widgetContext.featureFlags.length !== 0;

  const version = anyFlagPotential
    ? flags.length !== 0
      ? `v3/${flags.map(({ name }) => name).join('+')}`
      : 'v3'
    : VERSION;

  const config = {
    cookie_flags: COOKIE_FLAGS,
    anonymize_ip: true,
    send_page_view: false,
    accountId: widgetContext.accountId,
  };

  return {
    ...config,
    version,
  };
};

const useSetupAnalytics = (widgetContext: IWidgetContext | null) => {
  const defaultConfigOptions = useDefaultEventOptions();
  const gaConfigSet = useRef(false);

  useEffect(() => {
    if (widgetContext === null || gaConfigSet.current === true) return;

    gtag('config', process.env.NEXT_PUBLIC_GA_ID, defaultConfigOptions);

    if (!isNil(widgetContext.googleAnalytics4)) {
      gtag('config', `${widgetContext.googleAnalytics4}`, defaultConfigOptions);
    }

    gaConfigSet.current = true;
  }, [defaultConfigOptions, widgetContext]);

  // Watch for consent updates
  useEffect(() => {
    if (widgetContext === null) return;

    const serializedConsentParams = serializeConsentOptions(
      widgetContext.consentParams,
    );

    // Update consent settings for our GA ID
    gtag(
      'consent',
      'update',
      serializedConsentParams,
      process.env.NEXT_PUBLIC_GA_ID,
    );

    // Update consent settings for the customer' GA4 ID if defined
    if (!isNil(widgetContext.googleAnalytics4)) {
      gtag(
        'consent',
        'update',
        serializedConsentParams,
        widgetContext.googleAnalytics4,
      );
    }

    // Push consent update to the dataLayer for GTM to recognize (for all tags)
    window.dataLayer.push({
      event: 'consent_update',
      ...serializedConsentParams,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgetContext?.consentParams]);
};

export default useSetupAnalytics;
