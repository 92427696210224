import {} from 'react';
import useWidgetContext from '../useWidgetContext';

const usePathPrefix = (): string => {
  const context = useWidgetContext();

  return `/iframe/${context.accountId}`;
};

export default usePathPrefix;
