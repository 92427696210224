import ArrowLeft from './ArrowLeft';
import ArrowRight from './ArrowRight';
import ChevronDown from './ChevronDown';
import ChevronLeft from './ChevronLeft';
import ChevronRight from './ChevronRight';
import ChevronUp from './ChevronUp';
import Close from './Close';
import Fullscreen from './Fullscreen';
import ENGFlag from './ENGFLag';
import NLFlag from './NLFlag';
import Copy from './Copy';
import Check from './Check';

export type Props = React.SVGAttributes<SVGElement> & {
  color?: string;
};

export default {
  'arrow-left': ArrowLeft,
  'arrow-right': ArrowRight,
  'chevron-down': ChevronDown,
  'chevron-left': ChevronLeft,
  'chevron-right': ChevronRight,
  'chevron-up': ChevronUp,
  check: Check,
  close: Close,
  copy: Copy,
  engFlag: ENGFlag,
  fullscreen: Fullscreen,
  nlFlag: NLFlag,
};
