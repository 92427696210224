import React from 'react';
import styled, { css } from 'styled-components';
import useCopy from '../../hooks/useCopy';
import useHeightContext from '../../hooks/useHeightContext';
import CopyDeeplink from '../CopyDeeplink';
import Divider from '../Divider';
import JustificationContainer from '../JustificationContainer';

export type Props = {
  dataTestId?: string;
};

const Footer: React.FC<Props> = ({ dataTestId, ...rest }) => {
  const { footer } = useCopy();
  const { footerRef } = useHeightContext();

  return (
    <Container>
      <Divider margin={[null, null]} ref={footerRef} />
      <JustificationContainer
        justification="space-between"
        align="center"
        margin={[null, 's', null, null]}
      >
        <StyledJustification data-testid={dataTestId} {...rest} align="center">
          <Text>
            {footer}{' '}
            <StyledLink href="https://dathuis.nl/" target="_blank">
              DatHuis
            </StyledLink>
          </Text>
        </StyledJustification>
        <CopyDeeplink />
      </JustificationContainer>
    </Container>
  );
};

const MAX_HEIGHT = 52;
const Container = styled.div(
  ({ theme }) => css`
    position: relative;
    width: 100%;
    background: #fff;
    padding: 0;
    border-radius: 0 0 ${theme.getSystem().border.radius.base}
      ${theme.getSystem().border.radius.base};
  `,
);

const StyledLink = styled.a`
  color: #2676a5;
  text-decoration: none;
`;

const Text = styled.span(
  ({ theme }) => css`
    font-size: ${theme.fontSize('xxs')};
  `,
);
const StyledJustification = styled(JustificationContainer)(
  ({ theme }) => css`
    height: ${MAX_HEIGHT}px;
    padding: ${theme.space('xxs')} ${theme.space('base')};
  `,
);

export default Footer;
