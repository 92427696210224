import { NextRouter } from 'next/router';
import { LayoutT } from '../../../../shared/types';

type Args = {
  isFullScreen?: boolean;
  router: NextRouter;
  expanded?: boolean;
};

const getCurrentLayout = ({
  isFullScreen,
  router,
  expanded = false,
}: Args): LayoutT => {
  if (expanded === false) return 'collapsed';

  const onAppsPage = router.pathname.includes('apps');

  return isFullScreen && onAppsPage
    ? 'fullscreen'
    : onAppsPage
    ? 'wide'
    : 'narrow';
};

export default getCurrentLayout;
