import React from 'react';
import generateId from '../../utils/generateId';
import getUserId from '../../utils/getUserId';
import getSessionId from '../../utils/getSessionId';

export type ITrackingContext = {
  /**
   * userId would be across "widget instances"
   */
  userId: string;

  /**
   * accountId needs no explanation
   */
  accountId: string;

  /**
   * sessionId is session storage based
   */
  sessionId: string;

  /**
   * runtimeId should be this current run, so different tabs would have different runtimeIds
   */
  runtimeId: string;
};

export const UNSET_ID = 'unset';

export const DEFAULT_TRACKING_CONTEXT_VALUE: ITrackingContext = {
  userId: getUserId(),
  runtimeId: generateId('runtime'),
  sessionId: getSessionId(),
  accountId: UNSET_ID,
};

const TrackingContext = React.createContext<ITrackingContext>(
  DEFAULT_TRACKING_CONTEXT_VALUE,
);

export default TrackingContext;
