import { useRouter } from 'next/router';
import * as Sentry from '@sentry/react';
import {
  TrackMessageEvent,
  AppsOpenedEvent,
  CollapseWidgetEvent,
  DHApp,
  DeepLinkEvent,
  DismissWidgetEvent,
  ExpandWidgetEvent,
  OpenCommandEvent,
  SessionStartEvent,
  TrackingEvent,
  TrackingInfo,
  VirtualPageViewEvent,
  IframeApp,
} from '../../shared/types';
import gtag from '../../utils/gtag';
import useWidgetContext from '../useWidgetContext';
import { useDefaultEventOptions } from '../useSetupAnalytics';
import GoogleTracking from './vendors/google';
import DatHuisTracking from './vendors/dathuis';
import TrackingContext from '../../context/TrackingContext';
import { useContext } from 'react';
import useDHFlags from '../useDHFlags';
import useCurrentApp from '../useCurrentApp';
import type { ConsentParams } from '../../context/WidgetContext';

export const FIXED_WGT_EVENT_PREFIX = 'wgt';
export const FIXED_APP_EVENT_PREFIX = 'wgt_a';

const vendors = [GoogleTracking, DatHuisTracking];

const consentMap: {
  [key in TrackingEvent['event']]: keyof ConsentParams | 'necessary';
} = {
  AppsOpened: 'analytics_storage',
  CollapseWidget: 'analytics_storage',
  DeepLink: 'analytics_storage',
  DismissWidget: 'analytics_storage',
  ExpandWidget: 'analytics_storage',
  OpenCommand: 'analytics_storage',
  TrackMessage: 'analytics_storage',
  VirtualPageView: 'analytics_storage',
  // SessionStart can be counted as a necessary information to make sure we are getting any session started, meaning UW works
  SessionStart: 'necessary',
};

const isConsentGranted = (
  consentParams: ConsentParams,
  event: TrackingEvent,
) => {
  const eventName = event.event;
  const consentType = consentMap[eventName];
  if (!consentType) {
    Sentry.captureException(new Error(`Unhandled event type: ${event}`));
    return false;
  }
  // Always allow sending necessary events
  if (consentType === 'necessary') {
    return true;
  }

  return consentParams[consentType] === 'granted';
};

const useFireTrackingEvent = () => {
  const {
    apps,
    tracking: trackingEndpoint,
    consentParams,
  } = useWidgetContext();
  const currentApp = useCurrentApp();
  const featureFlags = useDHFlags().map(({ name }) => name);
  const trackingContext = useContext(TrackingContext);
  const defaultEventOptions = useDefaultEventOptions();
  const router = useRouter();

  const tracking: TrackingEventInfo = {
    endpoint: trackingEndpoint,
    featureFlags: featureFlags,
    ...trackingContext,
  };

  // Any context from hooks can be provided here
  const fireEvent = (event: TrackingEvent) => {
    /**
     * If consent is not granted, we are not allowed to send the event
     */
    if (!isConsentGranted(consentParams, event)) {
      return;
    }

    /**
     * Iterate over all vendors
     */
    vendors.forEach(vendor => {
      switch (event.event) {
        case 'VirtualPageView':
          return vendor.onPageView({
            event,
            apps,
            pathname: router.pathname,
            defaultEventOptions,
            tracking,
            currentApp,
          });

        case 'ExpandWidget':
          return vendor.onExpandWidget({
            event,
            defaultEventOptions,
            currentApp,
            tracking,
          });
        case 'DeepLink':
          return vendor.onDeepLink({ event, defaultEventOptions, tracking });
        case 'OpenCommand':
          return vendor.onOpenByCommand({
            event,
            defaultEventOptions,
            currentApp,
            tracking,
          });
        case 'CollapseWidget':
          return vendor.onCollapseWidget({
            event,
            pathname: router.pathname,
            defaultEventOptions,
            currentApp,
            tracking,
          });
        case 'DismissWidget':
          return vendor.onDismissWidget({
            event,
            pathname: router.pathname,
            defaultEventOptions,
            currentApp,
            tracking,
          });
        case 'AppsOpened':
          return vendor.onAppsOpened({
            event,
            pathname: router.pathname,
            defaultEventOptions,
            currentApp,
            tracking,
          });
        case 'SessionStart':
          return vendor.onSessionStart({
            event,
            pathname: router.pathname,
            defaultEventOptions,
            currentApp,
            tracking,
          });

        case 'TrackMessage':
          return vendor.onTrackMessage({
            event,
            pathname: router.pathname,
            defaultEventOptions,
            currentApp,
            tracking,
          });

        default: {
          Sentry.captureException(new Error(`Unhandled event ${event}`));
          return gtag('event', 'unhandled_event', {
            event_category: 'unhandled',
            event_label: 'Unhandled Event',
            payload: event,
            ...defaultEventOptions,
          });
        }
      }
    });
  };

  return fireEvent;
};

// Check `src/hooks/useFireTrackingEvent/vendors/dathuis/index.ts`
export type TrackingEventInfo = {
  endpoint?: TrackingInfo;
  userId: string;
  accountId: string;
  sessionId: string;
  runtimeId: string;
  featureFlags: Array<string>;
};

export type TrackingInterface = {
  onPageView: (args: {
    event: VirtualPageViewEvent;
    apps: Record<DHApp['slug'], DHApp>;
    pathname: string;
    defaultEventOptions: object;
    tracking?: TrackingEventInfo;
    currentApp?: IframeApp | null;
  }) => void;
  onCollapseWidget: (args: {
    event: CollapseWidgetEvent;
    pathname: string;
    defaultEventOptions: object;
    tracking?: TrackingEventInfo;
    currentApp?: IframeApp | null;
  }) => void;
  onExpandWidget: (args: {
    event: ExpandWidgetEvent;
    defaultEventOptions: object;
    tracking?: TrackingEventInfo;
    currentApp?: IframeApp | null;
  }) => void;
  onDeepLink: (args: {
    event: DeepLinkEvent;
    defaultEventOptions: object;
    tracking?: TrackingEventInfo;
    currentApp?: IframeApp | null;
  }) => void;
  onOpenByCommand: (args: {
    event: OpenCommandEvent;
    defaultEventOptions: object;
    tracking?: TrackingEventInfo;
    currentApp?: IframeApp | null;
  }) => void;
  onDismissWidget: (args: {
    event: DismissWidgetEvent;
    pathname: string;
    defaultEventOptions: object;
    tracking?: TrackingEventInfo;
    currentApp?: IframeApp | null;
  }) => void;
  onAppsOpened: (args: {
    event: AppsOpenedEvent;
    pathname: string;
    defaultEventOptions: object;
    tracking?: TrackingEventInfo;
    currentApp?: IframeApp | null;
  }) => void;
  onSessionStart: (args: {
    event: SessionStartEvent;
    pathname: string;
    defaultEventOptions: object;
    tracking?: TrackingEventInfo;
    currentApp?: IframeApp | null;
  }) => void;
  onTrackMessage: (args: {
    event: TrackMessageEvent;
    pathname: string;
    defaultEventOptions: object;
    tracking?: TrackingEventInfo;
    currentApp?: IframeApp | null;
  }) => void;
};

export default useFireTrackingEvent;
