import { useRouter } from 'next/router';
import useWidgetContext from '../useWidgetContext';

const useCurrentApp = () => {
  const router = useRouter();
  const { apps } = useWidgetContext();

  const slug = router.query.slug;
  if (!slug || typeof slug !== 'string') return null;

  return apps[slug] ?? null;
};

export default useCurrentApp;
