import React from 'react';
import { Props } from '..';

const MaxMortgage: React.FC<Props> = ({ color, ...props }) => (
  <svg
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M34.4893 47.8222H41.867"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M45.1559 39.7333V25.3333H46.9336C47.467 25.3333 47.7336 24.7111 47.2892 24.2667L30.9336 8.44445C30.4003 7.91112 29.4225 7.91112 28.8892 8.44445L12.4447 24.1778C12.0892 24.5333 12.267 25.2444 12.8003 25.2444H14.5781V36.9778"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.8447 23.8222L29.5114 13.5111C29.6892 13.3333 29.9558 13.3333 30.1336 13.5111L40.8892 23.8222"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M40.2666 8H36.3555V13.7778L40.2666 17.4222V8Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M52.2666 38.8446C50.1332 38.8446 48.3555 38.0446 48.3555 37.0668"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M56.1777 34.1337C56.1777 35.1115 54.3999 35.9115 52.2666 35.9115C50.1332 35.9115 48.3555 35.1115 48.3555 34.1337"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M52.2672 41.6889C51.3783 41.6889 50.5783 41.6 49.9561 41.3333"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M52.2671 44.6226C51.4671 44.6226 50.6671 44.5337 50.0449 44.3559"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M52.2666 33.0668C54.4266 33.0668 56.1777 32.2709 56.1777 31.2891C56.1777 30.3072 54.4266 29.5113 52.2666 29.5113C50.1065 29.5113 48.3555 30.3072 48.3555 31.2891C48.3555 32.2709 50.1065 33.0668 52.2666 33.0668Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M56.1777 36.978V31.2891"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M49.9561 47.1111C50.5783 47.3778 51.3783 47.4667 52.2672 47.4667"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M48.3555 31.2891V39.9113"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M60.622 44.9779C60.622 45.9556 58.8443 46.7556 56.7109 46.7556C54.5776 46.7556 52.7998 45.9556 52.7998 44.9779"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M60.622 42.0447C60.622 43.0225 58.8443 43.8225 56.7109 43.8225C54.5776 43.8225 52.7998 43.0225 52.7998 42.0447"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M60.622 47.8225C60.622 48.8003 58.8443 49.6003 56.7109 49.6003C54.5776 49.6003 52.7998 48.8003 52.7998 47.8225"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M60.622 50.7556C60.622 51.7334 58.8443 52.5334 56.7109 52.5334C54.5776 52.5334 52.7998 51.7334 52.7998 50.7556"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M56.7109 40.9779C58.871 40.9779 60.622 40.1819 60.622 39.2001C60.622 38.2182 58.871 37.4223 56.7109 37.4223C54.5509 37.4223 52.7998 38.2182 52.7998 39.2001C52.7998 40.1819 54.5509 40.9779 56.7109 40.9779Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M52.7998 39.2001V53.6001C52.7998 54.5779 54.5776 55.3779 56.7109 55.3779C58.8443 55.3779 60.622 54.5779 60.622 53.6001V39.2001"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M49.7783 47.2891C49.7783 48.2668 48.0005 49.0668 45.8672 49.0668C43.7339 49.0668 41.8672 48.3557 41.8672 47.2891"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M49.7783 44.4446C49.7783 45.4224 48.0005 46.2224 45.8672 46.2224C43.7338 46.2224 41.9561 45.4224 41.9561 44.4446"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M45.7783 43.2891C47.9383 43.2891 49.6894 42.4931 49.6894 41.5113C49.6894 40.5294 47.9383 39.7335 45.7783 39.7335C43.6183 39.7335 41.8672 40.5294 41.8672 41.5113C41.8672 42.4931 43.6183 43.2891 45.7783 43.2891Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41.8672 41.5113V50.1335C41.8672 51.1113 43.645 51.9113 45.7783 51.9113C47.9116 51.9113 49.6894 51.1113 49.6894 50.1335V41.5113"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M33.2446 43.6445H3.55566L5.33344 36.9779H31.4668L33.2446 43.6445Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M33.2446 43.6445V53.1556H3.55566V43.6445"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.7553 53.1556H13.0664V43.6445L13.9553 36.9779H21.8664L22.7553 43.6445V53.1556Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.3779 46.0445H32.6224"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.3779 43.6445H32.6224"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.3779 53.1556H32.6224"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.3779 48.4445H32.6224"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.3779 50.7556H32.6224"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.55566 46.0445H12.7112"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.55566 43.6445H12.7112"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.55566 53.1556H12.7112"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.55566 48.4445H12.7112"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.55566 50.7556H12.7112"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default MaxMortgage;
