import React, { MutableRefObject } from 'react';

export type IHeightContext = {
  headerRef: MutableRefObject<HTMLHRElement | null>;
  footerRef: MutableRefObject<HTMLHRElement | null>;
};

const HeightContext = React.createContext<IHeightContext>({
  headerRef: { current: null },
  footerRef: { current: null },
});

export default HeightContext;
