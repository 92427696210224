import { isNil } from 'ramda';
import { PostMessage } from '../../types';

const isValidMessage = (message: any): message is PostMessage => {
  if (typeof message === 'string') return false;
  if (isNil(message.type)) return false;

  return true;
};

export default isValidMessage;
