import React from 'react';
import { Props } from '..';

const Kidwk: React.FC<Props> = ({ color, ...props }) => (
  <svg
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M47.1996 29.9111V21.2H48.8885C49.4218 21.2 49.6885 20.5778 49.244 20.1333L33.4218 4.84444C32.8885 4.31111 31.9996 4.31111 31.4662 4.84444L15.4662 20.1333C15.1107 20.4889 15.2885 21.2 15.8218 21.2H17.5107V29.9111"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.6885 19.6889L31.9996 9.64444C32.1774 9.46666 32.444 9.46666 32.6218 9.64444L43.0218 19.6889"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M42.4882 13.5556V4.48889H38.666V9.55555"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M34.3116 39.0667L27.0227 42.7111C25.956 43.2444 24.6227 43.1556 23.6449 42.3556L22.0449 41.1111L31.9116 32.3111C32.6227 31.6889 35.2005 31.3333 36.0894 31.6889L44.1783 33.9111L51.9116 32.2222L53.8671 42.8889L47.556 45.4667L44.4449 47.6L34.3116 39.0667Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M32.6221 57.4667C34.7554 59.1556 36.711 58.5333 37.511 58.1778L46.0443 53.0222C48.5332 51.4222 47.5554 48.7556 46.4887 47.8667C45.6887 47.3333 46.2221 47.5111 46.2221 47.5111"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M31.3778 32.7556L28.0889 31.3333C27.1112 30.9778 26.0445 31.0667 25.0667 31.5111L18.0445 33.3778L13.0667 32.5778L11.8223 43.7778L17.5112 44.7556L20.0889 46.9778"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.8447 56.9333L33.8669 49.8222"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.8223 53.8222L30.0445 47.6"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.1553 51.3333L25.3331 46.9778"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.2441 46.0889C21.6886 45.5556 22.3997 45.4667 23.0219 45.7333L25.4219 46.8L26.8441 45.5556C27.1997 45.2 27.733 45.2 28.1775 45.3778L30.6663 46.6222L33.9552 49.5556L34.5775 51.8667C34.8441 53.0222 34.5775 54.2667 33.7775 55.1555L31.3775 57.8222C30.3997 58.8889 28.7997 58.9778 27.733 58.0889L19.9997 51.2444C18.933 50.3556 18.8441 48.6667 19.733 47.6L21.2441 46.0889Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Kidwk;
