import React from 'react';
import { Props } from '..';

const InterestTerm: React.FC<Props> = ({ color, ...props }) => (
  <svg
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M47.2005 37.5111V22.5778H48.8894C49.4228 22.5778 49.6894 21.9556 49.245 21.5111L33.4228 6.22222C32.8894 5.68889 32.0005 5.68889 31.4672 6.22222L15.4672 21.5111C15.1117 21.8667 15.2894 22.5778 15.8228 22.5778H17.5117V44.3556H23.3783"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.6895 21.0667L32.0006 11.0222C32.1783 10.8444 32.445 10.8444 32.6228 11.0222L43.0228 21.0667"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M42.4892 5.77778H38.667V11.3778L42.4892 14.9333V5.77778Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M39.1112 46.4889L31.2001 49.4222C30.1334 49.8667 28.889 49.8667 27.8223 49.4222C23.6445 47.9111 15.6445 44.9778 13.0668 44C9.86675 42.7556 6.48898 45.7778 9.42231 47.3778C12.4445 48.9778 27.5556 57.3333 27.5556 57.3333C28.5334 57.8667 29.689 58.1333 30.8445 58.1333H36.0001C36.8001 58.1333 37.5112 58.0444 38.2223 57.7778L48.889 54.0444L53.8668 54.1333V39.6444L43.7334 36.8C42.489 36.3556 41.0668 36.4444 39.8223 36.9778L28.889 41.3333C24.1779 43.5556 29.3334 46.4 29.3334 46.4L39.1112 44.4444V46.4889Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.889 27.4667C29.7236 27.4667 30.4002 26.7901 30.4002 25.9556C30.4002 25.121 29.7236 24.4444 28.889 24.4444C28.0545 24.4444 27.3779 25.121 27.3779 25.9556C27.3779 26.7901 28.0545 27.4667 28.889 27.4667Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M35.9115 34.4889C36.7461 34.4889 37.4226 33.8123 37.4226 32.9778C37.4226 32.1432 36.7461 31.4667 35.9115 31.4667C35.0769 31.4667 34.4004 32.1432 34.4004 32.9778C34.4004 33.8123 35.0769 34.4889 35.9115 34.4889Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.3779 34.4L37.1557 24.6222"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default InterestTerm;
