import DesignSystem from './System';
import {
  generateColorPalette,
  SystemColorPalette,
} from './System/Default/colorPalette';

import {
  zIndex,
  border,
  fontWeight,
  lineHeight,
  breakpoints,
  sizes,
  grid,
  SystemBreakpointMap,
  SystemZIndexMap,
  SystemSizeMap,
  SystemBorder,
  SystemLineHeightMap,
  SystemFontWeightMap,
  SystemGridMap,
  SystemBoxShadowMap,
  boxShadow,
} from './System/Default';
import { StyleConfig } from '../shared/types';

export type SystemType = {
  baseFontSize: string;
  fontFamily: { sans: string };
  fontWeight: SystemFontWeightMap;
  sizes: SystemSizeMap;
  lineHeight: SystemLineHeightMap;
};

export type System = {
  type: SystemType;
  breakpoints: SystemBreakpointMap;
  colors: { colorPalette: SystemColorPalette };
  zIndex: SystemZIndexMap;
  spacing: { scale: SystemSizeMap };
  border: SystemBorder;
  grid: SystemGridMap;
  boxShadow: SystemBoxShadowMap;
};

const defaultTheme: System = {
  type: {
    baseFontSize: '16px',
    sizes,
    fontFamily: {
      sans: '"Metropolis","Helvetica Neue","Helvetica","Arial",sans-serif',
    },
    fontWeight,
    lineHeight,
  },
  colors: {
    colorPalette: generateColorPalette({
      success: {
        background: '#3DA07B',
        color: '#ffffff',
      },
      error: {
        background: '#DD121B',
        color: '#ffffff',
      },
      primary: {
        background: '#35265E',
        color: '#fff',
      },
      secondary: {
        background: '#00A3C3',
        color: '#fff',
      },
    }),
  },
  spacing: {
    scale: sizes,
  },
  border,
  zIndex,
  breakpoints,
  grid,
  boxShadow,
};

const getTheme = (styleConfig: StyleConfig): DesignSystem => {
  const system: System = {
    ...defaultTheme,

    colors: {
      ...defaultTheme.colors,
      colorPalette: generateColorPalette(styleConfig),
    },
  };

  return new DesignSystem(system);
};

export default getTheme;
