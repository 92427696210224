import { ROOT } from '../../constants/paths';

/**
 *
 * @param pathname - full pathname may include query parameters but not domain or protocol
 * @returns
 */
const cleanPath = (pathname: string): string => {
  const _pathname = pathname.split('?')[0]; // Ignore search params

  const cleanPath = _pathname
    .replace(/\/iframe\/a---\w+-\w+-\w+-\w+-\w+/g, '')
    .trim();
  const guardedPath = cleanPath === '' ? ROOT : cleanPath;

  return guardedPath;
};
export default cleanPath;
