import { useCallback, useEffect } from 'react';
import { OnMessageHandler, PostMessage, SendMessage } from '../../shared/types';
import isValidMessage from '../../shared/utils/isValidMessage';
import log from '../../utils/log';

const useMessaging = (onMessage?: OnMessageHandler): SendMessage => {
  const sendMessage = useCallback((message: PostMessage) => {
    log('Sending message', message);
    // Make sure you are sending a string, and to stringify JSON
    window.parent.postMessage(JSON.stringify(message), '*');
  }, []);

  const messageHandler = useCallback(
    (event: MessageEvent) => {
      event.stopPropagation();
      event.preventDefault();

      try {
        // We only receive stringified messages, ignore any message that isn't stringified
        if (typeof event.data !== 'string') return;
        const parsedMessage: PostMessage = JSON.parse(event.data);
        log('Received message', event.data);
        // Also not a correct message, ignore it
        if (!isValidMessage(parsedMessage)) return;

        if (onMessage) {
          onMessage(parsedMessage);
        }
      } catch {
        if (
          typeof event.data === 'string' &&
          !event.data.includes('[iFrameSizer')
        ) {
          // eslint-disable-next-line no-console
          console.warn(`Unable to parse message \`${event.data}\``);
        }
      }
    },
    [onMessage],
  );

  useEffect(() => {
    window.addEventListener('message', messageHandler);

    return () => {
      window.removeEventListener('message', messageHandler);
    };
  }, [messageHandler]);

  return sendMessage;
};

export default useMessaging;
