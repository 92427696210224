import ARX from './ARX';
import BBWC from './BBWC';
import DatHuis from './DatHuis';
import InterestTerm from './InterestTerm';
import Kidwk from './Kidwk';
import MaxMortgage from './MaxMortgage';
import MortgageSavingsCheck from './MortgageSavingsCheck';

export type Props = {
  color?: string;
  strokeWidth?: number | string;
};

const illustrations = {
  'arx-groep': ARX,
  'bb-wc': BBWC,
  'interest-term': InterestTerm,
  'max-mortgage': MaxMortgage,
  'mortgage-savings-check': MortgageSavingsCheck,
  kidwk: Kidwk,
  dathuis: DatHuis,
};

export default illustrations;
