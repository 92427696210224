import styled, { css } from 'styled-components';
import { Position } from '../../../../../../../../shared/types';

export type Props = {
  dataTestId?: string;
};
export const CLOSE_BUTTON_SIZE = 32;
export const CLOSE_BUTTON_POSITION = 16;

const CloseButton = styled.div<{ $position: Position }>(
  ({ theme }) => css`
    position: absolute;
    width: ${CLOSE_BUTTON_SIZE}px;
    height: ${CLOSE_BUTTON_SIZE}px;
    padding: 4px;
    border-radius: ${theme.getSystem().border.radius.full};
    background: ${theme.color('secondary')};

    top: -${CLOSE_BUTTON_POSITION}px;
    right: -${CLOSE_BUTTON_POSITION}px;

    transition: all 0.3s ease-out;

    &:hover {
      transform: rotate(-90deg);
    }

    svg {
      color: ${theme.color('secondary', 'text')};
    }
  `,
);

export default CloseButton;
