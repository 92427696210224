import { useContext } from 'react';
import FrameSizeContext from '../../context/FrameSizeContext';

const useFrameSizeSelector = () => {
  const frameSizeSelector = useContext(FrameSizeContext);

  return frameSizeSelector;
};

export default useFrameSizeSelector;
