const generateId = (prefix: string): string => {
  if (!prefix)
    throw new Error(
      `Cannot generate an ID without a prefix. Received: ${prefix}`,
    );

  const timestamp = Date.now();
  const sessionString = `${timestamp}`;

  const generatedId = btoa(
    Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15) +
      sessionString,
  );

  return `${prefix}-${generatedId.substring(0, 32)}`;
};
export default generateId;
