import * as React from 'react';
import { Props } from '..';

const ChevronRight: React.FC<Props> = ({
  color = 'currentColor',
  strokeWidth = 2.044,
  ...props
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={strokeWidth}
      d="M6.6 22.9L17.5 12 6.6 1.1"
    />
  </svg>
);

export default ChevronRight;
