import generateId from '../generateId';
import safeSessionStorage from '../safeSessionStorage';

export const SESSION_ID_STORAGE_KEY = 'dh_session_id';

const getSessionId = (): string => {
  const idFromStorage = safeSessionStorage.getItem(SESSION_ID_STORAGE_KEY);

  if (idFromStorage) return idFromStorage;
  const newId = generateId('session');
  safeSessionStorage.setItem(SESSION_ID_STORAGE_KEY, newId);

  return newId;
};

export default getSessionId;
