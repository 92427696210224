import React from 'react';

const DatHuis = () => (
  <svg width="100%" height="100%" viewBox="0 0 1917.1 755.2" version="1.1">
    <path
      fill={'#2676a5'}
      className="prefix__st0"
      d="M770.8 571.3h53c6.8 0 12.2-5.5 12.2-12.2v-33c0-24-1.6-53.7 27.9-60.5 4.7-1.1 9.7-.5 14.5-.5h29.4c23.8 0 47.6 0 71.4-.1h12.1c6.8 0 12.2 5.5 12.2 12.2v258.2c0 6.8 5.5 12.2 12.2 12.2h56.8c6.8 0 12.2-5.5 12.2-12.2V269.9c0-6.8-5.5-12.2-12.2-12.2H1017c-6.8 0-12.2 5.5-12.2 12.2v86.8c0 17.6-19.3 39.7-37.3 40.4-10.7.4-163.4 0-163.4 0V351h-45.7v208c.2 6.8 5.7 12.3 12.4 12.3zM1130.3 454.8h75.7v166.4s-.4 31.4 12.5 43.1c13 11.7 26.3 18.8 51.4 18.8 25.1 0 42.6-8.4 51.4-25.5 9.2-17.9 7.1-38 7.1-57.5v-62.6-60.9-21.7h76.4v170.6s1.3 44.7-16.3 72.8c-17.6 28-57.3 56.9-117.5 56.9s-107.5-22.2-122.1-52.3c-14.6-30.1-15.9-49.3-16.7-57.3-.6-8.1-1.9-190.8-1.9-190.8zM1461.1 454.8h74.4v292.3h-74.4z"
    />
    <circle className="prefix__st0" cx={1499.2} cy={359.4} r={46.7} />
    <path
      fill={'#2676a5'}
      className="prefix__st0"
      d="M1794.7 487c-12.9 17.6-25.7 35.3-38.6 52.9.9-1.2-24.4-13.4-26.8-14.2-9.7-3.6-20.3-6.2-30.7-6.9-12.9-.8-34-1.9-37.6 14.4-4.5 20.1 23.3 26.5 37 31.1 19.7 6.6 40 11.7 58.6 21.3 16.5 8.5 32.3 20.7 40.9 37.5 13.9 27.2 8.9 66.9-7.6 92-26.3 40-87.6 45.6-130.4 36.4-28.9-6.2-55.7-21.7-80.2-37.8l39.3-56c-.7.9 26.4 16 29.2 17.3 11 5.2 22.7 9 34.7 10.8 16.1 2.4 49.3 4.1 51-19 1-14.2-11.7-18.7-22.4-23-14-5.7-28.4-10.5-42.9-14.8-23.8-7.1-50.4-18.2-67.1-37.4-21.8-25.1-17.7-72-1-98.2 10.6-16.6 28.5-31.2 47.5-37 23.1-7.1 63.5-10.6 96.6 1.6s50.5 29 50.5 29z"
    />
    <g>
      <path
        fill={'#4a4a4a'}
        className="prefix__st1"
        d="M369.7 225.9c-.4-12.8-1.7-25.6-3.8-38.3-6.2-37-20.9-72.8-44.4-102.1-13.7-17.1-30.4-31.9-49.2-43.2-40.2-24.3-87.5-37.8-134.1-41-38.3-2.7-77.6-2-116-1.3C14.7.1 7.5-.2 0 0v458.1c61.7 0 126 5.4 186.7-7.3 73.7-15.4 139.8-63.8 168.1-134.9 11.4-28.7 15.8-59.4 14.9-90zM198.9 369.8c-41.9 15.5-122 9.2-122 9.2V80.2c41.9-4.6 102 .9 141.2 19.1 39.2 18.2 69.2 48.3 71 130.1 1.8 81.8-48.3 124.9-90.2 140.4zM629.8 164.4v24s-29.8-25.8-81.3-27.5c-47.5-1.6-97.4 23.7-125.6 61.4-34.7 46.3-40.1 116.2-11.3 166.4 27.5 47.9 85.5 76.4 140 76.4 48 0 77.3-26.6 77.3-26.6v19.6h74.6V164.9l-73.7-.5zm-81.3 228.7c-44.3 0-80.2-35.9-80.2-80.2s35.9-80.2 80.2-80.2 80.2 35.9 80.2 80.2-35.9 80.2-80.2 80.2zM758.6 80.3v263.5c0 17.9 0 33.3 6.2 50.2 6.3 17.2 16 33.5 29.8 45.7 30.2 26.6 80.8 25.3 80.8 25.3h30.2v-67.8h-19.4c-12.4-.1-20.2.6-33.7-4.1-22.6-7.8-18.3-40.9-18-60.8v-4V265v-32.4H900c7.5 0 13.6-6.1 13.6-13.6v-44.8c0-7.5-6.1-13.6-13.6-13.6h-50.3c-7.5 0-13.6-6.1-13.6-13.6V80.3c0-7.5-6.1-13.6-13.6-13.6h-50.3c-7.5 0-13.6 6.1-13.6 13.6z"
      />
      <circle className="prefix__st1" cx={1888.2} cy={726} r={28.9} />
    </g>
  </svg>
);

export default DatHuis;
