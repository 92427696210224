import { isNil } from 'ramda';
import DesignSystem from '../../theme/System';
import { SystemSize } from '../../theme/System/Default';

/**
 * Transforms given values into a string format that css expects
 * @param {Array<SystemSize | null>} x - Example argument
 * keywords: css array, margin, padding
 */
const arrayToCss = (
  array: Array<SystemSize | null>,
  theme: DesignSystem,
): string =>
  array.reduce(
    (acc, item) => acc + ` ${!isNil(item) ? theme.space(item) : '0'}`,
    '',
  );

export default arrayToCss;
