import * as React from 'react';
import { Props } from '..';

const Fullscreen: React.FC<Props> = ({
  color = 'currentColor',
  strokeWidth = 1.5,
  ...props
}) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.6667 14L12.6667 14C13.0203 14 13.3594 13.8595 13.6095 13.6095C13.8595 13.3594 14 13.0203 14 12.6667L14 10.6667M2 10.6667L2 12.6667C2 13.0203 2.14048 13.3594 2.39052 13.6095C2.64057 13.8595 2.97971 14 3.33333 14L5.33333 14M5.33333 2L3.33333 2C2.97971 2 2.64057 2.14047 2.39053 2.39052C2.14048 2.64057 2 2.97971 2 3.33333L2 5.33333M14 5.33333L14 3.33333C14 2.97971 13.8595 2.64057 13.6095 2.39052C13.3594 2.14048 13.0203 2 12.6667 2L10.6667 2"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Fullscreen;
