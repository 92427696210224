/**
 * Determines whether the user is accessing the application or website from a mobile device.
 *
 * @function
 * @returns {boolean} Returns true if the user is accessing from a mobile device, and false otherwise.
 *
 * @example
 * const mobile = isMobile(); // true or false
 */

const isMobile = (): boolean =>
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent,
  );
export default isMobile;
