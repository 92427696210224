import { generateMedia, MediaGenerator } from 'styled-media-query';
import {
  SystemBreakpoint,
  SystemFontWeight,
  SystemLineHeight,
  SystemSize,
  BaseColorVariant,
  SystemZIndex,
  SystemBreakpointMap,
  SystemBoxShadow,
} from './Default';
import { BaseColor } from './Default/colorPalette';
import { System } from '../';

/** pxTo(): converts a `rem` or `em` value to `px` */
export const pxTo = (
  value: string | number,
  base: number,
  unit: string = 'rem',
): string => `${parseFloat(`${value}`) / base}${unit}`;

export const remToPx = (value: string | number, base: number): string =>
  `${parseFloat(`${value}`) * base}px`;

const colors = {
  white: '#fff',
  lightGrey: '#f7f7f7',
  grey: '#eae8ed',
  purple: '#35265E',
};

export default class DesignSystem {
  private ds: System;

  /**
   * mq()
   * get media query breakpoint name
   */
  public mq: MediaGenerator<SystemBreakpointMap, DesignSystem>;

  public colors = colors;

  constructor(system: System) {
    this.ds = system;
    this.mq = generateMedia(this.ds.breakpoints);
  }

  /**
   * get()
   * get any value from the design system object
   */
  public getSystem(): System {
    return this.ds;
  }

  /**
   * fontSize()
   * get a font-size value from the design system object
   */
  public fontSize(size: SystemSize): string {
    const currentBp = this.getCurrentBreakpoint();
    const parsedValue = parseFloat(this.ds.type.sizes[currentBp][size]);

    return this.pxToRem(parsedValue);
  }

  /**
   * fs()
   * get a font-size value from the design system object
   * @see fontSize()
   */
  public fs(size: SystemSize): string {
    return this.fontSize(size);
  }

  /**
   * fontWeight()
   * get a font-weight value from the design system object
   */
  public fontWeight(weight: SystemFontWeight): number {
    return this.ds.type.fontWeight[weight];
  }

  /**
   * fw()
   * get a font-weight value from the design system object
   * @see fontWeight()
   */
  public fw = this.fontWeight;

  /**
   * lineHeight()
   * get a line-height value from the design system object
   */
  public lineHeight(selector: SystemLineHeight): number {
    return this.ds.type.lineHeight[selector];
  }

  /**
   * lh()
   * get a line-height value from the design system object
   * @see lineHeight()
   */
  public lh = this.lineHeight;

  /**
   * spacing()
   * get a spacing value from the design system object
   */
  public spacing(val: SystemSize): string {
    const currentBp = this.getCurrentBreakpoint();
    const parsedValue = parseFloat(this.ds.spacing.scale[currentBp][val]);

    return this.pxToRem(parsedValue);
  }

  /**
   * space()
   * get a spacing value from the design system object
   * @see spacing()
   */
  public space(val: SystemSize): string {
    return this.spacing(val);
  }

  /**

  /**
   * boxShadow()
   *
   * get our default box shadow in the given color variant
   */

  public boxShadow(variant: SystemBoxShadow): string {
    return this.ds.boxShadow[variant];
  }

  /**
   * spacingBetween()
   *
   * get the absolute spacing between two SystemSizes
   */
  public spacingBetween(a: SystemSize, b: SystemSize): string {
    const currentBp = this.getCurrentBreakpoint();

    const aValue = parseFloat(this.ds.spacing.scale[currentBp][a]);
    const bValue = parseFloat(this.ds.spacing.scale[currentBp][b]);

    return this.pxToRem(Math.abs(aValue - bValue));
  }

  /**
   * spaceBetween()
   *
   * get the absolute spacing between two SystemFontSizes
   * @see spacingBetween()
   */
  public spaceBetween(a: SystemSize, b: SystemSize): string {
    return this.spacingBetween(a, b);
  }

  /**
   * color()
   * get a color from your color palette
   * `hue`: can contain a path to be traversed (eg: 'base.background.light'),
   * in that case the `variant` argument is ignored
   */
  public color(hue: BaseColor, variant: BaseColorVariant = 'base'): string {
    return this.ds.colors.colorPalette[hue][variant];
  }

  /**
   * bp()
   * get a breakpoint value from the design system object
   */
  public bp(breakpoint: SystemBreakpoint): string {
    return this.ds.breakpoints[breakpoint];
  }

  /**
   * z()
   * get a z-index value from the design system object
   */
  public z(z: SystemZIndex): number {
    return this.ds.zIndex[z];
  }

  /**
   * Returns the current system breakpoint based on the window size.
   * @returns {SystemBreakpoint} The current system breakpoint.
   */
  public getCurrentBreakpoint(): SystemBreakpoint {
    const breakpoints = this.ds.breakpoints;
    const keys = Object.keys(breakpoints) as Array<SystemBreakpoint>;

    const currentBp = keys.filter(
      // Filter the keys to get the current breakpoint
      key =>
        global.window.matchMedia(
          // Use matchMedia to get the current breakpoint
          `only screen and (max-width: ${breakpoints[key]})`,
        ).matches,
    );

    const lastBp = keys[keys.length - 1]; // Get the last breakpoint

    // Return the current breakpoint of the last
    // Some scenarios occur where all media queries match for some reason
    // In that case we default to the last breakpoint, likely desktop.
    return currentBp.length === keys.length ? lastBp : currentBp[0] || lastBp;
  }

  /**
   * By default we assume all values to come in pixels
   *
   * @param value
   */
  private pxToRem(value: number) {
    const baseFontSize = parseFloat(this.ds.type.baseFontSize);
    return pxTo(value, baseFontSize, 'rem');
  }

  /**
   * Convert rem to px, including the unit `px`
   */
  public remToPx(value: number | string): string {
    const baseFontSize = parseFloat(this.ds.type.baseFontSize);
    return remToPx(value, baseFontSize);
  }

  /**
   * Convert rem to px, returning only the number part
   */
  public remToPxRaw(value: number | string): number {
    return parseFloat(this.remToPx(value));
  }
}
