import React from 'react';
import { animated, useSpring } from 'react-spring';
import useCopy from '../../hooks/useCopy';
import { DHApp, Position, Appearance } from '../../shared/types';
import useLocale from '../../hooks/useLocale';
import Large from './components/Large';
import Small from './components/Small';
import illustrations from '../Illustration/components';

export type FacadeProps = React.HtmlHTMLAttributes<HTMLDivElement> & {
  position: Position;
  dataTestId?: string;
  highlight: DHApp;
  onDismiss: () => void;
  hasOnlyOneApp?: boolean;
  size: Appearance;
  onTrigger: (path: string) => void;
};

export type ComponentProps = Omit<
  FacadeProps,
  'size' | 'highlight' | 'onClick'
> & {
  header: React.ReactNode;
  headerLink: string;
  description?: React.ReactNode;
  illustration?: keyof typeof illustrations;
  icon: { src: string };
  footer: React.ReactNode;
  onDismiss: () => void;
};

const TriggerComponent: React.FC<FacadeProps> = ({
  dataTestId,
  position,
  hasOnlyOneApp = false,
  size,
  highlight,
  onDismiss,
  onTrigger,
}) => {
  const copy = useCopy();
  const locale = useLocale();

  const springConfig = springConfigs[position];
  const spring = useSpring({
    from: springConfig,
    to: {
      transform: 'translate(0px, 0px)',
      opacity: 1,
      width: '100%',
    },
    delay: 300,
  });

  const componentProps: ComponentProps = {
    dataTestId,
    header: highlight.title[locale],
    headerLink: `/apps/${highlight.slug}`,
    illustration: highlight.illustration,
    icon: highlight.icon,
    position: position,
    description: hasOnlyOneApp ? null : highlight.description[locale],
    footer: hasOnlyOneApp ? highlight.description[locale] : copy.trigger.footer,
    hasOnlyOneApp,
    onDismiss,
    onTrigger,
  };

  switch (size) {
    case Appearance.Large:
      return (
        <animated.div style={spring}>
          <Large {...componentProps} />
        </animated.div>
      );
    case Appearance.Small:
      return (
        <animated.div style={spring}>
          <Small {...componentProps} />
        </animated.div>
      );

    default:
      // eslint-disable-next-line no-console
      console.warn('[DatHuis Widget] Unknown size for trigger component', size);
      return null;
  }
};

const springConfigs: Record<
  Position,
  {
    transform: string;
    opacity: number;
    width: string;
  }
> = {
  // Pop from side(left or right)
  TopLeft: {
    transform: 'translate(-40px, 0px)',
    opacity: 0,
    width: '100%',
  },
  TopRight: {
    transform: 'translate(40px, 0px)',
    opacity: 0,
    width: '100%',
  },
  CenterLeft: {
    transform: 'translate(-40px, 0px)',
    opacity: 0,
    width: '100%',
  },
  CenterRight: {
    transform: 'translate(40px, 0px)',
    opacity: 0,
    width: '100%',
  },

  // Pop from bottom
  BottomLeft: {
    transform: 'translate(0px, 40px)',
    opacity: 0,
    width: '100%',
  },
  BottomRight: {
    transform: 'translate(0px, 40px)',
    opacity: 0,
    width: '100%',
  },
};

export default TriggerComponent;
