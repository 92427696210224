import * as Sentry from '@sentry/react';
import { NextRouter, useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';

const useDHRouter = () => {
  const router = useRouter();
  const [isNavigating, setIsNavigating] = useState<boolean>(false);

  const dhRouter: NextRouter = useMemo(() => {
    const mockRouter: NextRouter = {
      ...router,
      // We overwrite the 3 main navigation functions so we prevent nextjs from navigating mid transition
      push: async () => false,
      replace: async () => false,
      back: async () => false,
    };
    if (isNavigating) {
      return mockRouter;
    }
    return router;
  }, [isNavigating, router]);

  useEffect(() => {
    const handleRouteChange = () => {
      setIsNavigating(true);
    };

    const handleRouteChangeComplete = () => {
      setIsNavigating(false);
    };

    const handleRouteChangeError = (err: { cancelled: boolean }) => {
      Sentry.captureException(err);
      if (err.cancelled) {
        setIsNavigating(false);
      }
    };

    router.events.on('routeChangeStart', handleRouteChange);
    router.events.on('routeChangeComplete', handleRouteChangeComplete);
    router.events.on('routeChangeError', handleRouteChangeError);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
      router.events.off('routeChangeError', handleRouteChangeError);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router]);

  return dhRouter;
};

export default useDHRouter;
