import React, { Dispatch, SetStateAction } from 'react';
import { Locale } from '../../shared/types';

export type GlobalState = {
  appsOpened: {
    value: Array<string>;
    setValue: Dispatch<SetStateAction<Array<string>>>;
  };
  loading: {
    value: boolean;
    setValue: Dispatch<SetStateAction<boolean>>;
  };
  title: {
    value: string | null;
    setValue: Dispatch<SetStateAction<string | null>>;
  };
  locale: {
    value: Locale;
    setValue: Dispatch<SetStateAction<Locale>>;
  };
};

const noOp = () => {};

const StateContext = React.createContext<GlobalState>({
  appsOpened: {
    value: [],
    setValue: noOp,
  },
  loading: {
    value: false,
    setValue: noOp,
  },
  title: {
    value: null,
    setValue: noOp,
  },
  locale: {
    value: 'nl',
    setValue: noOp,
  },
});

export default StateContext;
